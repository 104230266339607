import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { EmailIcon, TwitterIcon, WhatsappIcon } from "react-share";
const ShareList = (props) => {
  
  const { showshareListModal, shareListData, closeshareListModal } = props;

  return (
    <div>
      <Modal isOpen={showshareListModal}>
        <ModalHeader toggle={closeshareListModal}>
          <p>Share List</p>
        </ModalHeader>
        <ModalBody>
          <WhatsappShareButton
            title={shareListData}
            url={"https://tootle.sofmen.com"}
            windowWidth={1000}
            windowHeight={650}
          >
            <WhatsappIcon size="30" round={true}></WhatsappIcon>
          </WhatsappShareButton>
          <EmailShareButton
            body={shareListData}
            url={"https://tootle.sofmen.com"}
            windowWidth={1000}
            windowHeight={650}
          >
            <EmailIcon size="30" round={true} />
          </EmailShareButton>
          <TwitterShareButton
            title={shareListData}
            url={"https://tootle.sofmen.com"}
            windowWidth={1000}
            windowHeight={650}
          >
            <TwitterIcon size="30" round={true} />
          </TwitterShareButton>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={closeshareListModal}
            className="custom-btn"
            size="sm"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ShareList;
