import React, { useState, useEffect } from "react";
import UploadCameraImage from "./UploadCameraImage";
import UploadImage from "./UploadImage";
import { updateMemory, deleteMedia } from "../service/MemoryService";
import DeleteMedia from "./DeleteMedia";
import UploadFiles from "./UploadFiles";
import DetailHeader from "../history/DetailHeader";
import { memoryDetail } from "../service/MemoryService";
import { useNavigate } from "react-router-dom";
import {
  hidehistoryDetailOnMobile,
  setReminderTime,
  setReminderId,
  fetchUsersAllMemory,
  setReminderRepeatType,
  setcustomRepeatDuration,
  setrepeatOccurence,
  setcustomReminderUnit,
  setcustomReminderEndDateType,
  setMemoryId,
  setMemoryName,
  setTotalShared,
  setSharedData,
} from "../redux";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import pdfImage from "../assets/images/pdf.png";
import ReactPlayer from "react-player/lazy";
import Reminder from "../reminder/Reminder";
import { authentication } from "../firebase/firebase";
import LogoutConfirm from "../auth/LogoutConfirm";
import ShareMedia from "./ShareMedia";
import ShareAllMedia from "./ShareAllMedia";
import AllFilesPreview from "./AllFilesPreview";
import { connect } from "react-redux";
import EditCameraImageModal from "./EditCameraImageModal";
import Linkify from "react-linkify";
import { deviceType } from "../comman/Common";
import ShareWithContactModal from "../share/ShareWithContactModal";
import Caption from "./Caption";
import axios from "axios";
const MemoryDetail = (props) => {
  const controller = new AbortController();
  const navigate = useNavigate();

  const { memoryId, showNameInEditMode } = props;

  /* const "uId" store userId */
  const [uId, setuId] = useState("");

  /* const "loader" for loading data */
  const [loader, setloader] = useState(true);

  /* const "uploadImageModal" responsible for showing upload image modal */
  const [showUploadImageModal, setshowUploadImageModal] = useState(false);

  /* const "play" responsible and play and pause of audio button */
  const [play, setplay] = useState(true);

  /* const "uploadCameraImageModal" responsible for showing upload image modal throw camera*/
  const [showCamera, setshowCamera] = useState(false);

  /* const "disableButton" disable and enable the button */
  const [disableButton, setdisableButton] = useState(false);

  /* const "showUploadFilesModal" responsible for open and closed upload file modal */
  const [showUploadFilesModal, setshowUploadFilesModal] = useState(false);

  /* const "showDeleteMediaModal" responsible for open and closed delete file modal */
  const [showDeleteMediaModal, setshowDeleteMediaModal] = useState(false);

  /* const "mediaId" store mediaId */
  const [mediaId, setmediaId] = useState(null);

  /* const "dropdownOpen" open dropdown of upload file */
  const [dropdownOpen, setdropdownOpen] = useState(false);

  /* const "dropdownOpen" open dropdown of upload file */
  const [shareDropdownToggle, setshareDropdownToggle] = useState(false);

  /* const "showLogoutConfirm" responsible for open and closed logout modal */
  const [showLogoutConfirm, setshowLogoutConfirm] = useState(false);

  /* const "showShareMediaModal" responsible for open and closed showShareMediaModal modal */
  const [showShareMediaModal, setshowShareMediaModal] = useState(false);

  /*  const "shareMediaUrl" store shareMedia url */
  const [shareMediaUrl, setshareMediaUrl] = useState(null);

  /*const "showAllshareFileModal" store  all shareMedia url  */
  const [showAllshareFileModal, setshowAllshareFileModal] = useState(false);

  /* const "allAttachmentUrl" store all Attachment url  */
  const [allAttachmentUrl, setallAttachmentUrl] = useState("");

  /* const "defaultAttachmentUrl" store defualt url */
  const [defaultAttachmentUrl, setdefaultAttachmentUrl] =
    useState("https://tootle.sofmen.com/");

  /* const "files" store selected file */
  const [files, setfiles] = useState([]);

  /* const "allReadFilesObject" custom store file object  */
  const [allReadFilesObject, setallReadFilesObject] = useState([]);

  /* const "filesLoader" loader for uploading  file */
  const [filesLoader, setfilesLoader] = useState(true);

  /* const  "allImageFiles" store all Image File */
  const [allImageFiles, setallImageFiles] = useState([]);

  /* const "allReadImageFilesObjects" store all image file object*/
  const [allReadImageFilesObjects, setallReadImageFilesObjects] = useState([]);

  /*const "showAllfilesPreview" hide and show popup of preview of attachment  */
  const [showAllfilesPreview, setshowAllfilesPreview] = useState(false);

  /* const "filePreviewIndex" store index of preview attachment */
  const [filePreviewIndex, setfilePreviewIndex] = useState(0);

  /* const "memoryName" store memory Name  */
  const [memoryName, setmemoryName] = useState();

  /* const "memoryDetails" store memory detail */
  const [memoryDetails, setmemoryDetails] = useState();

  /* const "memoryNameInEditMode" show memory name in edit mode */
  const [memoryNameInEditMode, setmemoryNameInEditMode] = useState(false);

  /* const  "deletMediaLoder" show loader for delete media*/
  const [deletMediaLoder, setdeletMediaLoder] = useState(false);

  /* const "rerender"  rerender the component */
  const [rerender, setrerender] = useState(true);

  /* const "showEditCameraImageModal" show and hide camera popup */
  const [showEditCameraImageModal, setshowEditCameraImageModal] =
    useState(false);

  /* const  "cameraImageSrc" store camera image src*/
  const [cameraImageSrc, setcameraImageSrc] = useState("");

  /* const "disablebtn" add image button */
  const [disablebtn, setdisablebtn] = useState(false);

  /* const "showshareWithContactModal" hide and show share memory contact modal */
  const [showshareWithContactModal, setshowShareWithContactModal] =
    useState(false);

  /* const "dropdownOpen" open dropdown of upload file */
  const [showUpdateSharedUserModal, setshowUpdateSharedUserModal] =
    useState(false);

  /*const "caption" store caption value  */
  const [caption, setcaption] = useState("");

  const [percentCompleted, setpercentCompleted] = useState(0);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setuId(userId);
    setmemoryDetails([]);
    setloader(true);
    setpercentCompleted(0);
    getMemoryDetail();
    setmemoryName("");
    if (showNameInEditMode) {
      setmemoryNameInEditMode(true);
    } else {
      setmemoryNameInEditMode(false);
    }
    return () => {
      speechSynthesis.cancel();
      // cancel the request
      controller.abort();
    };
  }, [memoryId]);

  useEffect(() => {
    window.onpopstate = (e) => {
      props.hidehistoryDetailOnMobile();
      if (memoryDetails.length > 0) {
        if (memoryName !== "" && memoryDetails[0]?.memoryText !== memoryName) {
          const userId = localStorage.getItem("userId");
          let formData = new FormData();
          formData.append("memoryId", memoryId); //append the values with key, value pair
          formData.append("text", memoryName);
          formData.append("userId", userId);
          updateMemory(formData)
            .then((response) => {
              if (response.status == 200) {
                props.fetchUsersAllMemory();
              } else {
                toast.success(response?.data?.message, {
                  className: "toast-container_OTP",
                  position: "top-right",
                  autoClose: 3000,
                });
              }
            })
            .catch((error) => {
              toast.error("Something went wrong", {
                className: "toast-container_OTP",
                position: "top-right",
                autoClose: 3000,
              });
            });
        }
      }
    };
  });

  /**
   * This function will fetch MemoryDetail of memory
   */
  const getMemoryDetail = () => {
    let userId = localStorage.getItem("userId");
    memoryDetail(memoryId, userId, controller)
      .then((response) => {
        if (response.status === 200) {
          setmemoryDetails(response.data);
          if (response.data?.length > 0) {
            props.setMemoryId(response.data[0]?.memoryId);
            props.setMemoryName(response.data[0]?.memoryText);
            if (response.data[0]?.reminder?.length > 0) {
              props.setReminderTime(
                response.data[0]?.reminder[0].reminder_time
              );
              props.setReminderId(response.data[0]?.reminder[0].reminder_id);
              props.setReminderRepeatType(
                response.data[0]?.reminder[0].repeat_type
              );
              props.setcustomRepeatDuration(
                response.data[0]?.reminder[0].custom_repeat_duration
              );
              props.setrepeatOccurence(
                response.data[0]?.reminder[0].repeat_occurrence
              );
              props.setcustomReminderUnit(
                response.data[0]?.reminder[0].custom_reminder_unit
              );
              props.setcustomReminderEndDateType(
                response.data[0]?.reminder[0].repeat_occurrence
                  ? "Occurrences"
                  : "Never"
              );
            } else {
              props.setReminderTime("");
              props.setReminderId("");
              props.setReminderRepeatType("");
              props.setcustomRepeatDuration("");
              props.setrepeatOccurence("");
              props.setcustomReminderUnit("");
              props.setcustomReminderEndDateType("");
            }
            if (response.data[0].share?.total_shared) {
              props.setTotalShared(response.data[0].share?.total_shared);
            } else {
              props.setTotalShared("");
            }
            if (response.data[0].share?.shared_data?.length > 0) {
              props.setSharedData(response.data[0].share?.shared_data);
            } else {
              props.setSharedData([]);
            }
          }
          setloader(false);
        } else {
          setmemoryDetails([]);
          setloader(false);
          toast.error(response?.data?.message, {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        setmemoryDetails([]);
        setloader(false);
        toast.error("Something went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This function will give updated memory detail
   */
  const fetchUpdatedHistory = () => {
    props.hidehistoryDetailOnMobile();
    if (memoryName !== "" && memoryDetails[0]?.memoryText !== memoryName) {
      const userId = localStorage.getItem("userId");
      let formData = new FormData();
      formData.append("memoryId", memoryId); //append the values with key, value pair
      formData.append("text", memoryName);
      formData.append("userId", userId);
      updateMemory(formData)
        .then((response) => {
          if (response.status == 200) {
          } else {
            toast.success(response?.data?.message, {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    }
    navigate("/memory");
  };

  /**
   * Upload multiple image than convert to base 64 and store the image object in the state
   * @param {object} e
   * @returns
   */

  const onChangeImage = (e) => {
    if (e.target.files) {
      /* Get files in array form */
      setfilesLoader(true);
      setshowUploadImageModal(true);
      const getallImageFiles = Array.from(e.target.files);
      const mergeAllImageFiles = allImageFiles.concat(getallImageFiles);
      setallImageFiles(mergeAllImageFiles);
      let valid = false;
      for (var j = 0; j < getallImageFiles.length; j++) {
        var sCurExtension = getallImageFiles[j];
        const checkFileTyype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];
        if (
          checkFileTyype == ".jpeg" ||
          checkFileTyype == ".jpg" ||
          checkFileTyype == ".png"
        ) {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
      if (!valid) {
        setshowUploadImageModal(false);
        setallImageFiles([]);
        setallReadImageFilesObjects([]);
        toast.error("file Not supported", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getallImageFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  name: file.name,
                  caption: "",
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (images) => {
            const mergeallReadImageFilesObjects =
              allReadImageFilesObjects.concat(images);
            setallReadImageFilesObjects(mergeallReadImageFilesObjects);
            setfilesLoader(false);
          },
          (error) => {
            console.error(error);
          }
        );
      }
      return false;
    } else {
      console.log("hii else");
    }
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log("percentCompleted", percentCompleted);
      setpercentCompleted(percentCompleted);
    },
  };

  /**
   * This funciton send image
   * @param {object} allImageFiles
   */
  const sendImageOnServer = (allImageFiles, allReadImageFilesObjects) => {
    setdisablebtn(true);
    const userId = localStorage.getItem("userId");
    setdisableButton(true);
    const createCaptionArray = allReadImageFilesObjects.map(
      (readImageFilesObjects) => {
        return readImageFilesObjects.caption;
      }
    );
    let formData = new FormData();
    formData.append("memoryId", memoryId);
    formData.append("userId", userId);
    formData.append("caption", JSON.stringify(createCaptionArray));
    allImageFiles.forEach((imageFile) => {
      formData.append("media", imageFile);
    });
    updateMemory(formData, config, controller)
      .then((response) => {
        if ((response.status = 200)) {
          console.log("response", response);
          setdisableButton(false);
          memoryDetails[0].mediaUrl = response.data[0].mediaUrl;
          setmemoryDetails(memoryDetails);
          setrerender(!rerender);
          setshowUploadImageModal(false);
          setdisablebtn(false);
          setfilesLoader(true);
          setallImageFiles([]);
          setallReadImageFilesObjects([]);
        } else {
          setdisableButton(false);
          setallImageFiles([]);
          setdisablebtn(false);
          setallReadImageFilesObjects([]);
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
        setpercentCompleted(0);
      })
      .catch((error) => {
        setdisableButton(false);
        setallImageFiles([]);
        setdisablebtn(false);
        setallReadImageFilesObjects([]);
        setpercentCompleted(0);
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This funciton will close Image upload PopUp
   */
  const closeUploadImageModal = () => {
    // cancel the request
    controller.abort();
    setshowUploadImageModal(false);
    setallImageFiles([]);
    setallReadImageFilesObjects([]);
    setfilesLoader(true);
    setdisableButton(false);
  };

  /**
   * This funciton will open camera click image in edit mode on popup
   * @param {string} imgSrc
   */
  const opneEditCameraImageModal = (imgSrc) => {
    setshowCamera(false);
    setcameraImageSrc(imgSrc);
    setshowEditCameraImageModal(true);
  };

  /**
   * This function will send image on server
   * @param {string} memoryId
   * @param {object} selectedFile
   */
  const sendCameraImageOnServer = (selectedFile, caption) => {
    const userId = localStorage.getItem("userId");
    setdisableButton(true);
    let formData = new FormData();
    formData.append("memoryId", memoryId);
    formData.append("media", selectedFile);
    formData.append("userId", userId);
    formData.append("caption", JSON.stringify([caption]));
    updateMemory(formData, config, controller)
      .then((response) => {
        setshowEditCameraImageModal(false);
        if ((response.status = 200)) {
          // updateHistoryDetail(memoryId);
          memoryDetails[0].mediaUrl = response.data[0].mediaUrl;
          setmemoryDetails(memoryDetails);
          setrerender(!rerender);
          setdisableButton(false);
          setshowCamera(false);
        } else {
          setdisableButton(false);
          setshowCamera(false);
          setshowEditCameraImageModal(false);
          toast.success(response?.data?.message, {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setdisableButton(false);
        setshowCamera(false);
        setshowEditCameraImageModal(false);
        toast.success("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * Upload multiple file than convert to base 64 and store the image object in the state
   * @param {object} e
   * @returns
   */
  const onChangeDocument = (event) => {
    if (event.target.files) {
      const getFiles = Array.from(event.target.files);
      const mergeAllFiles = files.concat(getFiles);
      setfiles(mergeAllFiles);
      setshowUploadFilesModal(true);
      /* Get files in array form */
      let valid = false;
      for (var j = 0; j < getFiles.length; j++) {
        var sCurExtension = getFiles[j];

        const filetype = sCurExtension.name.match(/\.[0-9a-z]+$/i)[0];
        if (
          filetype == ".pdf" ||
          filetype == ".m4v" ||
          filetype == ".mov" ||
          filetype == ".mp4" ||
          filetype == ".jpg" ||
          filetype == ".jpeg" ||
          filetype == ".png"
        ) {
          valid = true;
        } else {
          valid = false;
          break;
        }
      }
      if (!valid) {
        setshowUploadFilesModal(false);
        setallReadFilesObject([]);
        setfiles([]);
        toast.error("File type not supported", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        /* Map each file to a promise that resolves to an array of image URI's */
        Promise.all(
          getFiles.map((file, index) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve({
                  path: ev.target.result,
                  mime_type: file.type,
                  name: file.name,
                  caption: "",
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (files) => {
            /* Once all promises are resolved, update state with image URI array */
            const mergeFiles = allReadFilesObject.concat(files);
            setallReadFilesObject(mergeFiles);
            setfilesLoader(false);
          },
          (error) => {
            console.error(error);
          }
        );
      }
      return false;
    } else {
      console.log("Hi Else");
    }
  };

  /**
   * This function will upload file on server
   * @param {object} files
   */
  const uploadDocument = (files, allReadFilesObject) => {
    console.log("allReadFilesObject", allReadFilesObject);
    setdisablebtn(true);
    const userId = localStorage.getItem("userId");
    setdisableButton(true);
    const createCaptionArray = allReadFilesObject.map((readFilesObject) => {
      return readFilesObject.caption;
    });
    console.log("createCaptionArray", createCaptionArray);
    let formData = new FormData();
    formData.append("memoryId", memoryId);
    formData.append("userId", userId);
    formData.append("caption", JSON.stringify(createCaptionArray));
    files.forEach((file) => {
      const filetype = file.name.match(/\.[0-9a-z]+$/i)[0];
      if (filetype == ".pdf" || filetype == ".mp3") {
        formData.append("doc", file);
      } else {
        if (
          filetype == ".m4v" ||
          filetype == ".mov" ||
          filetype == ".mp4" ||
          filetype == ".jpg" ||
          filetype == ".jpeg" ||
          filetype == ".png"
        ) {
          formData.append("media", file);
        }
      }
    });
    updateMemory(formData, config, controller)
      .then((response) => {
        if (response.status === 200) {
          // updateHistoryDetail(memoryId);
          memoryDetails[0].mediaUrl = response.data[0].mediaUrl;
          setmemoryDetails(memoryDetails);
          setrerender(!rerender);
          setdisableButton(false);
          setshowUploadFilesModal(false);
          setfilesLoader(true);
          setdisablebtn(false);
          setallReadFilesObject([]);
          setfiles([]);
        } else {
          setshowUploadFilesModal(false);
          setallReadFilesObject([]);
          setfiles([]);
          setdisablebtn(false);
          setdisableButton(false);
          toast.error(response?.data?.message, {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
        setpercentCompleted(0);
      })
      .catch((err) => {
        console.log(err);

        setshowUploadFilesModal(false);
        setallReadFilesObject([]);
        setfiles([]);
        setdisablebtn(false);
        setdisableButton(false);
        setpercentCompleted(0);
        toast.error(err?.message, {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This function will close upload file popup
   */
  const closeUploadFilesModal = () => {
    console.log(controller);
    // cancel the request
    controller.abort();
    setshowUploadFilesModal(false);
    setallReadFilesObject([]);
    setfiles([]);
    setfilesLoader(true);
    setdisableButton(false);
  };

  const updateHistoryDetail = (memoryId) => {
    memoryDetail(memoryId, uId)
      .then((response) => {
        if (response.status === 200) {
          setmemoryDetails(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * this funciton will open all media share file
   */
  const openAllshareFile = () => {
    if (memoryDetails?.length > 0) {
      let NoteText = memoryDetails[0].memoryText + "\n\n";
      let AllAttachment = NoteText;
      if (memoryDetails[0].mediaUrl?.length > 0) {
        AllAttachment += "Attachments" + "\n\n";
        memoryDetails[0].mediaUrl?.map((media, index) => {
          const caption = media.caption ? media.caption + "\n" : "";
          if (memoryDetails[0].mediaUrl?.length - 1 !== index) {
            AllAttachment +=
              index + 1 + " " + caption + media.file_path + "\n\n";
          } else {
            AllAttachment += index + 1 + " " + caption;
            setdefaultAttachmentUrl(media.file_path);
          }
        });
      }
      setallAttachmentUrl(AllAttachment);
      setshowAllshareFileModal(true);
    }
  };

  /**
   * This function will opne text in edit mode
   * @param {string} listName
   */
  const showMemoryNameInEditMode = (listName) => {
    setmemoryName(listName);
    setmemoryNameInEditMode(true);
  };

  /**
   * This function will memory Text
   * @param {event} event
   */
  const updateText = (event) => {
    const userId = localStorage.getItem("userId");
    if (
      event.target.value !== "" &&
      memoryDetails[0].memoryText !== event.target.value
    ) {
      const copyNoteText = memoryDetails[0].memoryText;
      memoryDetails[0].memoryText = event.target.value.trim();
      setmemoryDetails(memoryDetails);
      setmemoryNameInEditMode(false);
      let formData = new FormData();
      formData.append("memoryId", memoryId); //append the values with key, value pair
      formData.append("userId", userId); //append the values with key, value pair
      formData.append("text", event.target.value.trim());
      updateMemory(formData)
        .then((response) => {
          if (response.status == 200) {
            props.fetchUsersAllMemory();
          } else {
            memoryDetails[0].memoryText = copyNoteText;
            setmemoryDetails(memoryDetails);
            setrerender(!rerender);
            toast.success(response?.data?.message, {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          setmemoryNameInEditMode(false);
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      setmemoryNameInEditMode(false);
    }
  };

  /**
   * This function will pause memory name play
   */
  const pauseMemoryName = () => {
    if ("speechSynthesis" in window) {
      speechSynthesis.cancel();
      setplay(true);
    }
  };

  /**
   * This function will play memory name audio
   */
  const playMemoryName = async () => {
    if ("speechSynthesis" in window) {
      speechSynthesis.cancel();
      setplay(false);
      let memory = new SpeechSynthesisUtterance();
      memory.lang = "en-US";
      memory.rate = 1;

      let memoryText = "";
      let numberLength = 0;
      if (memoryDetails[0]?.memoryText) {
        for (
          let letter = 0;
          letter < memoryDetails[0]?.memoryText.length;
          letter++
        ) {
          if (
            memoryDetails[0].memoryText.charAt(letter) === "1" ||
            memoryDetails[0].memoryText.charAt(letter) === "2" ||
            memoryDetails[0].memoryText.charAt(letter) === "3" ||
            memoryDetails[0].memoryText.charAt(letter) === "4" ||
            memoryDetails[0].memoryText.charAt(letter) === "5" ||
            memoryDetails[0].memoryText.charAt(letter) === "6" ||
            memoryDetails[0].memoryText.charAt(letter) === "7" ||
            memoryDetails[0].memoryText.charAt(letter) === "8" ||
            memoryDetails[0].memoryText.charAt(letter) === "9" ||
            memoryDetails[0].memoryText.charAt(letter) === "0"
          ) {
            if (numberLength !== 4) {
              memoryText =
                memoryText + memoryDetails[0].memoryText.charAt(letter);
              numberLength = numberLength + 1;
            } else {
              memoryText =
                memoryText + " " + memoryDetails[0].memoryText.charAt(letter);
              numberLength = 0;
            }
          } else {
            numberLength = 0;
            memoryText =
              memoryText + memoryDetails[0]?.memoryText.charAt(letter);
          }
        }
      }
      memory.text = memoryText;
      speechSynthesis.speak(memory);
      memory.onend = function (event) {
        setplay(true);
      };
    }
  };

  /**
   * This funciton will give you a tomorrow date
   * @returns
   */
  const getTomorrowDate = () => {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    now.setHours(8);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  };

  /**
   * This function will formate reminder time
   * @param {string} reminder_time
   * @returns
   */
  const formateReminderTime = (reminder_time) => {
    const date = new Date();
    const selectedYear = moment(reminder_time).format("YYYY");
    const currentYear = date.getFullYear().toString();
    if (
      moment(reminder_time).format("YYYY-MM-DD") ==
      moment(date).format("YYYY-MM-DD")
    ) {
      return "Today " + moment(reminder_time).format("h:mm a");
    }
    if (
      moment(reminder_time).format("YYYY-MM-DD") ==
      moment(getTomorrowDate()).format("YYYY-MM-DD")
    ) {
      return "Tomorrow " + moment(reminder_time).format("h:mm a");
    }

    if (currentYear === selectedYear) {
      return moment(reminder_time).format("MMM Do, h:mm a");
    } else {
      return moment(reminder_time).format("MMM Do YYYY, h:mm a");
    }
  };

  /**
   * This function will open share file popup
   * @param {string} url
   */
  const openShareMediaModal = (url, caption) => {
    setshareMediaUrl(url);
    setcaption(caption);
    setshowShareMediaModal(true);
  };

  /**
   * open delete media Modal
   * @param {string} data
   */
  const openDeleteMediaModal = (data) => {
    setmediaId(data.memory_media_id);
    setshowDeleteMediaModal(true);
  };

  /**
   * This function will delete media
   * @param {string} mediaId
   */
  const handleDeleteMedia = (mediaId) => {
    setdeletMediaLoder(true);
    setdisableButton(true);
    deleteMedia(mediaId)
      .then((res) => {
        updateHistoryDetail(memoryId);
        setshowDeleteMediaModal(false);
        setdisableButton(false);
        setdeletMediaLoder(false);
      })
      .catch((error) => {
        setshowDeleteMediaModal(false);
        setdisableButton(false);
        setdeletMediaLoder(false);
      });
  };

  /**
   * This function will open a preview of file
   * @param {number} index
   */
  const openFilePreview = (index) => {
    setfilePreviewIndex(index);
    setshowAllfilesPreview(true);
  };

  /**
   * logout the application
   */
  const Logout = () => {
    authentication.signOut();
  };

  return (
    <div>
      <DetailHeader fetchUpdatedHistory={fetchUpdatedHistory} />
      {loader ? (
        <div className="custom-loader">
          <Spinner animation="grow" size="sm" />
        </div>
      ) : (
        <div>
          {memoryDetails.length > 0 ? (
            <div>
              <div
                className="d-flex justify-content-end align-items-start px-3 py-2"
                style={{ borderBottom: "2px solid #ededed" }}
              >
                <Reminder getMemoryDetail={getMemoryDetail} />

                <ButtonDropdown
                  isOpen={dropdownOpen}
                  toggle={() => setdropdownOpen(!dropdownOpen)}
                  size="sm"
                >
                  <DropdownToggle caret>
                    <i className="fas fa-paperclip"></i> Attachment
                  </DropdownToggle>

                  <DropdownMenu>
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        multiple
                        onChange={onChangeImage}
                      />
                      Image
                    </label>

                    {deviceType() !== "mobile" && (
                      <div>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => setshowCamera(true)}
                          data-type="camera"
                        >
                          Camera
                        </DropdownItem>
                      </div>
                    )}
                    <DropdownItem divider />
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        accept=".pdf,video/*,image/png,image/jpg,image/jpeg"
                        multiple
                        onChange={onChangeDocument}
                      />
                      Document
                    </label>
                  </DropdownMenu>
                </ButtonDropdown>
                <div style={{ paddingLeft: "5px" }}>
                  <ButtonDropdown
                    isOpen={shareDropdownToggle}
                    toggle={() => setshareDropdownToggle(!shareDropdownToggle)}
                    size="sm"
                  >
                    <DropdownToggle caret>
                      <i
                        className="fas fa-share-alt"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      Share
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={openAllshareFile}>
                        Other
                      </DropdownItem>

                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => setshowShareWithContactModal(true)}
                      >
                        Share to collaborate
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
              <div className="all_history_details">
                <div className="p-3 custom-border-bottom">
                  <div className="d-flex my-2">
                    {memoryNameInEditMode ? (
                      <TextareaAutosize
                        className="memory-name-in-textarea"
                        spellCheck="false"
                        placeholder="New Note"
                        value={memoryName}
                        onChange={(event) => {
                          setmemoryName(event.target.value);
                        }}
                        onBlur={(event) => updateText(event)}
                        ref={(ref) => ref && ref.focus()}
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                      />
                    ) : (
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a target="_blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        <div
                          onClick={() =>
                            showMemoryNameInEditMode(
                              memoryDetails[0].memoryText === ""
                                ? "New Note"
                                : memoryDetails[0].memoryText
                            )
                          }
                          className="memory-name"
                        >
                          {memoryDetails[0].memoryText === ""
                            ? "New Note"
                            : memoryDetails[0].memoryText}
                        </div>
                      </Linkify>
                    )}
                    {play ? (
                      <div>
                        <span onClick={playMemoryName}>
                          <i className="fas fa-play-circle play-btn"></i>
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span onClick={pauseMemoryName}>
                          <i className="fas fa-pause-circle pause-btn"></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    {props.reminderTime && (
                      <div className="memory_reminder_time">
                        <i className="far fa-clock clock-icon"></i>
                        <p>{formateReminderTime(props.reminderTime)}</p>
                      </div>
                    )}
                  </div>

                  {props.totalShared && (
                    <div
                      className="my-2 p-2 sharedData"
                      onClick={() => setshowShareWithContactModal(true)}
                    >
                      <i className="fas fa-share-alt share-icon"></i>
                      Shared with {props.totalShared} people
                    </div>
                  )}
                </div>

                <div className=" px-3 py-3">
                  <div className="">
                    {memoryDetails?.[0].mediaUrl?.length > 0 && (
                      <h6>Media Attachments</h6>
                    )}
                    {memoryDetails?.[0].mediaUrl?.length > 0 &&
                      memoryDetails?.[0].mediaUrl.map((Url, index) => {
                        return (
                          <React.Fragment>
                            <div>
                              {Url.media_type === "image" ||
                              Url.media_type === "image/jpeg" ||
                              Url.media_type === "image/jpg" ||
                              Url.media_type === "image/png" ? (
                                <div className="image_list" key={index}>
                                  <div
                                    className="img-view"
                                    onClick={() => openFilePreview(index)}
                                    key={index}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src={Url.file_path}
                                      alt="image"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="option">
                                    <span
                                      className="share"
                                      onClick={() =>
                                        openShareMediaModal(
                                          Url.file_path,
                                          Url.caption
                                        )
                                      }
                                    >
                                      <i className="fas fa-share-alt"></i>
                                    </span>
                                    <span
                                      className="delete"
                                      onClick={() => openDeleteMediaModal(Url)}
                                    >
                                      <i
                                        className="fa fa-times"
                                        style={{ width: "10px" }}
                                      ></i>
                                    </span>
                                  </div>
                                  {Url.caption && <Caption Url={Url} />}
                                </div>
                              ) : (
                                ""
                              )}
                              {Url.media_type === "application" && (
                                <div className="pdf_list_container">
                                  <div className="pdf_list">
                                    <li key={index} className="">
                                      <div className="d-flex flex-column">
                                        <div
                                          onClick={(e) => e.stopPropagation()}
                                          className="pdf-icon"
                                          style={{
                                            backgroundImage: `url(${pdfImage})`,
                                          }}
                                        ></div>
                                        <div style={{ marginTop: "10px" }}>
                                          <a
                                            href={Url.file_path}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {Url.file_path
                                              .split("\\")
                                              .pop()
                                              .split("/")
                                              .pop()}
                                          </a>
                                        </div>
                                      </div>
                                      <div className="option">
                                        <span
                                          className="share"
                                          onClick={() =>
                                            openShareMediaModal(Url.file_path)
                                          }
                                        >
                                          <i className="fas fa-share-alt"></i>
                                        </span>
                                        <span
                                          className="delete"
                                          onClick={() =>
                                            openDeleteMediaModal(Url)
                                          }
                                        >
                                          <i
                                            className="fa fa-times"
                                            style={{ width: "10px" }}
                                          ></i>
                                        </span>
                                      </div>
                                      {Url.caption && <Caption Url={Url} />}
                                    </li>
                                  </div>
                                </div>
                              )}

                              {Url.media_type === "video" && (
                                <div className="d-flex align-item-center flex-column my-3 video">
                                  <ReactPlayer
                                    url={Url.file_path}
                                    controls={true}
                                    className="video-sec"
                                    style={{
                                      width: "100%!important",
                                      height: "auto",
                                      background: "black",
                                    }}
                                  />
                                  <div className="option">
                                    <span
                                      className="share"
                                      onClick={() =>
                                        openShareMediaModal(Url.file_path)
                                      }
                                    >
                                      <i className="fas fa-share-alt"></i>
                                    </span>
                                    <span
                                      className="delete"
                                      onClick={() => openDeleteMediaModal(Url)}
                                    >
                                      <i
                                        className="fa fa-times"
                                        style={{ width: "10px" }}
                                      ></i>
                                    </span>
                                  </div>
                                  {Url.caption && <Caption Url={Url} />}
                                </div>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                  {showUploadImageModal && (
                    <UploadImage
                      showUploadImageModal={showUploadImageModal}
                      filesLoader={filesLoader}
                      allImageFiles={allImageFiles}
                      allReadImageFilesObjects={allReadImageFilesObjects}
                      onChangeImage={onChangeImage}
                      sendImageOnServer={sendImageOnServer}
                      disableButton={disableButton}
                      disablebtn={disablebtn}
                      percentCompleted={percentCompleted}
                      closeUploadImageModal={closeUploadImageModal}
                    />
                  )}
                  {showCamera && (
                    <UploadCameraImage
                      showCamera={showCamera}
                      closeCamera={() => setshowCamera(false)}
                      opneEditCameraImageModal={opneEditCameraImageModal}
                      disableButton={disableButton}
                    />
                  )}
                  {showDeleteMediaModal && (
                    <DeleteMedia
                      showDeleteMediaModal={showDeleteMediaModal}
                      closeDeleteMediaModal={() =>
                        setshowDeleteMediaModal(false)
                      }
                      mediaId={mediaId}
                      deletMediaLoder={deletMediaLoder}
                      handleDeleteMedia={handleDeleteMedia}
                      disableButton={disableButton}
                    />
                  )}
                  {showUploadFilesModal && (
                    <UploadFiles
                      showUploadFilesModal={showUploadFilesModal}
                      filesLoader={filesLoader}
                      allReadFilesObject={allReadFilesObject}
                      files={files}
                      onChangeDocument={onChangeDocument}
                      uploadDocument={uploadDocument}
                      disableButton={disableButton}
                      disablebtn={disablebtn}
                      percentCompleted={percentCompleted}
                      closeUploadFilesModal={closeUploadFilesModal}
                    />
                  )}
                  {showLogoutConfirm && (
                    <LogoutConfirm
                      showLogoutConfirm={showLogoutConfirm}
                      closeLogoutConfirm={() => setshowLogoutConfirm(false)}
                      Logout={Logout}
                    />
                  )}
                  {showShareMediaModal && (
                    <ShareMedia
                      showShareMediaModal={showShareMediaModal}
                      shareMediaUrl={shareMediaUrl}
                      caption={caption}
                      closeShareMediaModal={() => setshowShareMediaModal(false)}
                    />
                  )}
                  {showAllshareFileModal && (
                    <ShareAllMedia
                      showAllshareFileModal={showAllshareFileModal}
                      defaultAttachmentUrl={defaultAttachmentUrl}
                      memoryName={memoryDetails[0].memoryText}
                      allAttachmentUrl={allAttachmentUrl}
                      closeAllshareFileModal={() =>
                        setshowAllshareFileModal(false)
                      }
                    />
                  )}

                  {showAllfilesPreview && (
                    <AllFilesPreview
                      showAllfilesPreview={showAllfilesPreview}
                      memoryDetails={memoryDetails}
                      filePreviewIndex={filePreviewIndex}
                      closeAllfilesPreview={() => setshowAllfilesPreview(false)}
                    />
                  )}
                  {showEditCameraImageModal && (
                    <EditCameraImageModal
                      showEditCameraImageModal={showEditCameraImageModal}
                      disableButton={disableButton}
                      cameraImageSrc={cameraImageSrc}
                      sendCameraImageOnServer={sendCameraImageOnServer}
                      closeEditCameraImageModal={() => {
                        // cancel the request
                        controller.abort();
                        setshowEditCameraImageModal(false);
                      }}
                    />
                  )}
                  {showshareWithContactModal && (
                    <ShareWithContactModal
                      showshareWithContactModal={showshareWithContactModal}
                      getMemoryDetail={getMemoryDetail}
                      closeshareWithContactModal={() =>
                        setshowShareWithContactModal(false)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="custom_no_datafound">
              <p>No Record Found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sharedData: state.history.sharedData,
    totalShared: state.history.totalShared,
    reminderTime: state.history?.reminderTime,
    reminderId: state.history?.reminderId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSharedData: (sharedData) => dispatch(setSharedData(sharedData)),
    setTotalShared: (totalShared) => dispatch(setTotalShared(totalShared)),
    fetchUsersAllMemory: () => dispatch(fetchUsersAllMemory()),
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
    setReminderTime: (reminderTime) => dispatch(setReminderTime(reminderTime)),
    setReminderId: (reminderId) => dispatch(setReminderId(reminderId)),
    setReminderRepeatType: (reminderRepeatType) =>
      dispatch(setReminderRepeatType(reminderRepeatType)),
    setcustomRepeatDuration: (customRepeatDuration) =>
      dispatch(setcustomRepeatDuration(customRepeatDuration)),
    setrepeatOccurence: (repeatOccurence) =>
      dispatch(setrepeatOccurence(repeatOccurence)),
    setcustomReminderUnit: (customReminderUnit) =>
      dispatch(setcustomReminderUnit(customReminderUnit)),
    setcustomReminderEndDateType: (customReminderEndDateType) =>
      dispatch(setcustomReminderEndDateType(customReminderEndDateType)),
    setMemoryId: (memoryId) => dispatch(setMemoryId(memoryId)),
    setMemoryName: (memoryName) => dispatch(setMemoryName(memoryName)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MemoryDetail);
