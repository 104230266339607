import React, { useState } from "react";
import { addItem } from "../service/ContentService";
import TextareaAutosize from "react-textarea-autosize";
import Linkify from "react-linkify";
import { memoryDetail } from "../service/MemoryService";
const AddSectionItem = (props) => {
  const { sectionId, userId, setlistDetail, listId } = props;

  /* const "showaddItemInEditMode" show item name in edit mode */
  const [showaddItemInEditMode, setshowaddItemInEditMode] = useState(false);

  /* const "showCopyItemText" toggle for showing copy item text */
  const [showCopyItemText, setshowCopyItemText] = useState(false);

  /* const "sectionItems" store section items  */
  const [sectionItems, setsectionItems] = useState([]);

  /* const "copyItemText"  store item text */
  const [itemText, setitemText] = useState("");

  /* const "copyItemText"  store copy of item text */
  const [copyItemText, setcopyItemText] = useState("");

  /**
   * This function will add new item
   */
  const addNewItem = () => {
    setshowaddItemInEditMode(true);
  };

  /**
   * This function will handle item text
   * @param {object} event
   */
  const handleItemText = (event) => {
    setitemText("");
    setshowaddItemInEditMode(false);
    setcopyItemText(event.target.value);
    setshowCopyItemText(true);
  };

  /**
   * This funciton will add new item
   * @param {object} event
   */
  const saveAddItem = (event) => {
    if (event.target.value !== "") {
      setshowCopyItemText(false);
      setcopyItemText("");
      const itemText = { itemText: event.target.value };
      sectionItems.push(itemText);
      setsectionItems(sectionItems);
      let formData = new FormData();
      formData.append("userId", userId);
      formData.append("sectionId", sectionId);
      formData.append("itemName", event.target.value);
      addItem(formData)
        .then((response) => {
          if (response.status === 200) {
            updateListDetail();
          }
        })
        .catch((error) => {
          // updateListDetail();
        });
    } else {
      setshowCopyItemText(false);
    }
  };

  /**
   * This function will update list detail
   */
  const updateListDetail = () => {
    memoryDetail(listId, userId)
      .then((response) => {
        if (response.status === 200) {
          sectionItems.shift();
          setsectionItems(sectionItems);
          setlistDetail(response.data);
        }
      })
      .catch((error) => {
        sectionItems.shift();
        console.log(error);
      });
  };

  /**
   * This function will capitalize the first lettar
   * @param {string} string
   * @returns
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {sectionItems.length > 0 &&
        sectionItems.map((item, index) => {
          return (
            <div className="d-flex section-item" key={`index${sectionItems}`}>
              <div>
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="4.21053"
                    height="4.21053"
                    rx="1"
                    fill="#B2BAC9"
                  />
                  <rect
                    x="5.78955"
                    width="4.21053"
                    height="4.21053"
                    rx="1"
                    fill="#B2BAC9"
                  />
                  <rect
                    x="5.78955"
                    y="5.47363"
                    width="4.21053"
                    height="4.21053"
                    rx="1"
                    fill="#B2BAC9"
                  />
                  <rect
                    y="5.47363"
                    width="4.21053"
                    height="4.21053"
                    rx="1"
                    fill="#B2BAC9"
                  />
                  <rect
                    y="10.9473"
                    width="4.21053"
                    height="4.21053"
                    rx="1"
                    fill="#B2BAC9"
                  />
                  <rect
                    x="5.78955"
                    y="10.9473"
                    width="4.21053"
                    height="4.21053"
                    rx="1"
                    fill="#B2BAC9"
                  />
                </svg>
              </div>

              <div className="custom-check">
                <input type="checkbox" disabled />
              </div>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="_blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                <div style={{ flex: 1 }}>
                  <p className="items">{item.itemText}</p>
                </div>
              </Linkify>
              <div className="upload-icon">
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2H15V9H17V2C17 0.897 16.103 0 15 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H10V14H2V2Z"
                    fill="#B2BAC9"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M6 8L3 12H14L10 6L7 10L6 8Z"
                    fill="#B2BAC9"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M17 11H15V14H12V16H15V19H17V16H20V14H17V11Z"
                    fill="#B2BAC9"
                    fill-opacity="0.8"
                  />
                </svg>
              </div>
              <div className="completed-item-canclebtn">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="9" cy="9" r="9" fill="black" fill-opacity="0.5" />
                  <path
                    d="M13.8606 5.20473L12.8912 4.23535L9.0481 8.07848L5.20497 4.23535L4.2356 5.20473L8.07872 9.04785L4.2356 12.891L5.20497 13.8604L9.0481 10.0172L12.8912 13.8604L13.8606 12.891L10.0175 9.04785L13.8606 5.20473Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          );
        })}

      {showCopyItemText && (
        <div className="d-flex section-item">
          <div>
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="4.21053" height="4.21053" rx="1" fill="#B2BAC9" />
              <rect
                x="5.78955"
                width="4.21053"
                height="4.21053"
                rx="1"
                fill="#B2BAC9"
              />
              <rect
                x="5.78955"
                y="5.47363"
                width="4.21053"
                height="4.21053"
                rx="1"
                fill="#B2BAC9"
              />
              <rect
                y="5.47363"
                width="4.21053"
                height="4.21053"
                rx="1"
                fill="#B2BAC9"
              />
              <rect
                y="10.9473"
                width="4.21053"
                height="4.21053"
                rx="1"
                fill="#B2BAC9"
              />
              <rect
                x="5.78955"
                y="10.9473"
                width="4.21053"
                height="4.21053"
                rx="1"
                fill="#B2BAC9"
              />
            </svg>
          </div>
          <div className="custom-check">
            <input type="checkbox" disabled />
          </div>
          <TextareaAutosize
            className="details_item_name"
            spellCheck="false"
            value={capitalizeFirstLetter(copyItemText)}
            type="text"
            onChange={(event) => setcopyItemText(event.target.value)}
            onBlur={(event) => saveAddItem(event, sectionId)}
            ref={(ref) => ref && ref.focus()}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
          />
          <div className="upload-icon">
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H15V9H17V2C17 0.897 16.103 0 15 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H10V14H2V2Z"
                fill="#B2BAC9"
                fill-opacity="0.8"
              />
              <path
                d="M6 8L3 12H14L10 6L7 10L6 8Z"
                fill="#B2BAC9"
                fill-opacity="0.8"
              />
              <path
                d="M17 11H15V14H12V16H15V19H17V16H20V14H17V11Z"
                fill="#B2BAC9"
                fill-opacity="0.8"
              />
            </svg>
          </div>
          <div className="completed-item-canclebtn">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="9" cy="9" r="9" fill="black" fill-opacity="0.5" />
              <path
                d="M13.8606 5.20473L12.8912 4.23535L9.0481 8.07848L5.20497 4.23535L4.2356 5.20473L8.07872 9.04785L4.2356 12.891L5.20497 13.8604L9.0481 10.0172L12.8912 13.8604L13.8606 12.891L10.0175 9.04785L13.8606 5.20473Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      )}

      <div className="d-flex" style={{ padding: "5px 0px 6px 15px" }}>
        <i
          className="fas fa-plus"
          style={{
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "4px",
          }}
        />
        {showaddItemInEditMode ? (
          <TextareaAutosize
            placeholder="Add Item"
            spellCheck="false"
            className="details_item_name"
            value={capitalizeFirstLetter(itemText)}
            onChange={(event) => handleItemText(event)}
            autoFocus
          />
        ) : (
          <div
            onClick={() => addNewItem(sectionId)}
            style={{ color: "#636c72" }}
          >
            Add Item
          </div>
        )}
      </div>
    </>
  );
};

export default AddSectionItem;
