import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from "formik";

import logo from "../assets/images/NewLogo.png";
import { Button, FormGroup } from "reactstrap";
import * as Yup from "yup";
import TextError from "./TextError";
import { authentication } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
const Support = () => {
  const [toogle, settoogle] = useState(true);
  const navigate = useNavigate();
  

  const initialValues = {
    name: "",
    email: "",
    comments: "",
  };

  const onSubmit = (values, submitProps) => {
    navigate("/support/thankyou")
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Enter a valid email").required("Required"),
    comments: Yup.string().required("Required"),
  });

  const validateComments = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };

  function onChange(token) {
    if (token) {
      settoogle(false);
    } else {
      settoogle(true);
    }
  }

  const Logout = () => {
    localStorage.removeItem("userId");
    localStorage.clear();
    authentication.signOut();
    navigate("/");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => {
        
        return (
          <div className="feedbackForm">
              <a href={"https://tootle.sofmen.com"} target="_blank" rel="noreferrer">
            <h4>
              <img src={logo} width="180" />
            </h4>
            </a>
            <Form>
              <h3>Support</h3>
              <FormGroup>
                <Field type="text" id="name" name="name" placeholder="Name" />
                <ErrorMessage name="name" component={TextError} />
              </FormGroup>
              <FormGroup>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
                
                <ErrorMessage name="email" component={TextError} />
              </FormGroup>

              <FormGroup>
                <Field
                  as="textarea"
                  id="comments"
                  name="comments"
                  validate={validateComments}
                  placeholder="Comments"
                />
                <ErrorMessage name="comments" component={TextError} />
              </FormGroup>

              <ReCAPTCHA
                sitekey="6LfPlWUfAAAAAElBewDtiko7WAJ-3tUcUzHeMeJ6"
                onChange={onChange}
              />

              <Button
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting || toogle}
              >
                Submit
              </Button>
            </Form>
            <div className="bottom mb-1">
              <div>
                for any queries please email us :
                <a href="mailto: tootle.help@gmail.com"> tootle.help@gmail.com </a>
              </div>
            </div>
            <div className="bottom">
              <div>
                visit our website :
                <a href={"https://tootle.sofmen.com"} target="_blank" rel="noreferrer">
                  {" "}
                  https://tootle.sofmen.com
                </a>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Support;
