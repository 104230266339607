import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo1 from "../../assets/images/logo.svg";
import { Button, FormGroup } from "reactstrap";
import * as Yup from "yup";
import TextError from "../../Pages/TextError";
import { useNavigate } from "react-router-dom";
const AdminLogin = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, submitProps) => {
    navigate("/support/thankyou");
    submitProps.setSubmitting(false);
    submitProps.resetForm();
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Enter a valid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => {
        return (
          <div className="feedbackForm" style={{ padingTop: "0px" }}>
            <a href={"https://tootle.sofmen.com"} target="_blank" rel="noreferrer">
              <h4>
                <img src={logo1} width="180" />
              </h4>
            </a>
            <Form>
              <h3>Account Login</h3>

              <FormGroup>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
                <ErrorMessage name="email" component={TextError} />
              </FormGroup>

              <FormGroup>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="password"
                />
                <ErrorMessage name="password" component={TextError} />
              </FormGroup>

              <Button
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Login
              </Button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AdminLogin;
