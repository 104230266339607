import React from "react";
import logo from "../assets/images/NewLogo.png";
const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="TermsAndCondition">
        <a href={"https://tootle.sofmen.com"} target="_blank" rel="noreferrer">
          <h4 className="mb-4">
            <img src={logo} width="180" />
          </h4>
        </a>
        <h3>Privacy Policy</h3>
        <div>
          <h5>Thank you for using Tootle!</h5>
          <p>
            We are the operators of the app named Tootle. Our goal is to help
            you simplify your daily life. Your privacy is important to us, and
            we always want to be absolutely transparent about the way Tootle
            handles your personal data. This document is our Privacy Policy, and
            by using Tootle, you agree to be bound by this Privacy Policy so
            please read this document fully. You can contact us at any time if
            you have any questions and comments about this Privacy Policy.
          </p>
        </div>
        <div>
          <h5>What information we collect and how we use it?</h5>
          <p>
            If you enable backups and data synchronization between your devices
            using iCloud, then Tootle will transmit your tasks and categories to
            your personal iCloud digital storage that resides in the servers
            that are owned and operated by Apple. We will not share or transmit
            any of your tasks with any other third-party service. We do not have
            any of your tasks and do not transmit any of your tasks to any other
            service or server besides the iCloud servers as previously described
            above.
          </p>
          <p>
            We may use third-party services in Tootle in order to help us
            respond to support requests, detect and diagnose any potential
            issues and understand how Tootle is used so that we can improve your
            overall experience. We never share any of your tasks or memories
            with any of those services. Those third-party services may use and
            store anonymous aggregate data such as device characteristics,
            location data, version of Tootle installed on your device, the OS
            version your device is running on and whether or not you have access
            to our premium features. If any of those services require your name
            or email address, for example in case you want to contact our
            support team, then those services will ask you explicitly to share
            this information.
          </p>

          <p>
            Tootle.sofmen.com collects and store your uploaded media, files and
            contacts.
            <br />
            <br /> There are two types of notes :
            <br />
            1. Memory
            <br />
            2. List
            <br />
            <br />
          </p>
        </div>
        <div>
          <h5>Analytics and crash reporting</h5>
          <p>
            Third-party services such as Firebase Analytics (a Google service)
            are used to support, maintain, and improve Tootle. Your tasks are
            never shared with these services. Those third-party services may use
            and store anonymous aggregate data such as device identifiers of the
            devices, device characteristics, current app version, iOS version
            that your device is running on. None of these will require your name
            or email address.
          </p>
        </div>
        <div>
          <h5>Changes to these terms</h5>
          <p>
            We may update our Privacy Policy periodically. You are advised to
            review this Privacy Policy regularly for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
            If the changes are significant, we may provide more prominent notice
            or get your consent as required by law. We will not change this
            agreement to allow the collection of your data or make other
            significant changes without your consent.
          </p>
        </div>
        <div>
          <h5>Contact Us</h5>
          <p>Phone: +1 (844) 592-4033, (+91) 98936-59535</p>
          <p>Email: support@sofmen.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
